<template>
  <ContentWrapper id="AreaGroup">
    <div class="content-heading">
      區域群組列表
      <router-link target="_blank" :to="{ name: 'area-group-shipping-fee' }" class="ml-auto btn btn-primary" >添加群組
      </router-link>
    </div>

    <div class="card card-default">
      <div class="card-body">
        <div class="pt-2 pb-5">
          <table class="table">
            <thead>
            <tr>
              <th>區域群組名稱</th>
              <!--<th>區域群組描述</th>-->
              <!--<th>包含地區</th>-->
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="areaGroup in groups" :key="areaGroup.id">
              <td>{{ areaGroup.name }}</td>
              <!--<td>{{ areaGroup.description }}</td>-->
              <!--<td>-->
                <!--<span class="d-block" v-for="district in areaGroup.districts" :key="district">{{ district }}</span>-->
              <!--</td>-->
              <td>
                <router-link class="btn btn-success" target="_blank" :to="{ name: 'area-group-shipping-fee', params: { id: areaGroup.id }}">
                  編輯 <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                </router-link>
                <router-link class="btn btn-success ml-3" target="_blank" :to="{ name: 'config', params: { id: areaGroup.id }}">
                  配置 <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                </router-link>
                <template>
                  <button type="button" class="btn btn-danger ml-3" @click="removeAreaGroup(areaGroup.id)">
                   刪除
                  </button>
                </template>

              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </ContentWrapper>
</template>
<script>
// import { mapState, mapGetters } from 'vuex';
import { getLoadingInstance, showErrorMessage } from '@/helpers';

export default {
  name: 'AreaGroup',
  mounted() {
    this.getData();
  },
  data() {
    return {
      groups: {},
    };
  },
  watch: {},
  computed: {
  },
  methods: {
    getData() {
      const loading = getLoadingInstance();
      this.$api.group.get().then(({ data: response }) => {
        this.groups = response.data;
      }).catch(errors => showErrorMessage(errors)).finally(() => {
        loading.close();
      });
    },
    removeAreaGroup(id) {
      this.$confirm('是否要刪除這個區域群組？', '提示', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const loading = getLoadingInstance();
        this.$api.group.remove(id).then(() => {
          this.getData();
          this.$notify.success({
            title: '區域群組已刪除',
          });
        }).catch(errors => showErrorMessage(errors)).finally(() => {
          loading.close();
        });
      });
    },

  },
};
</script>
<style lang="scss">

</style>
