<template>
  <ContentWrapper id="area-group-shipping-fee">
    <div class="content-heading">收費設置-{{ shippingFeeForm.name }}</div>
    <div class="card card-default">
      <div class="card-body">
        <el-form :model="shippingFeeForm" :rules="shippingFeeFormRules" ref="shippingFeeForm" label-width="150px" >
          <el-form-item prop="name" label="名稱">
            <el-input v-model="shippingFeeForm.name" placeholder="輸入區域群組名稱" clearable />
          </el-form-item>

          <el-form-item label="文件費用設置">
            <table class="table">
              <tbody>
              <tr>
                <th>重量</th>
                <th>收費</th>
                <th>操作</th>
              </tr>
              <tr v-for="(set, key) in fileSettings" :key="key">
                <td>{{ set.weight }}</td>
                <td>
                  <el-input-number v-model="set.fee" :min="0" :step="1" :precision="1"></el-input-number>
                </td>
                <td>
                  <el-button type="danger" @click="removeFileSet(set.weight)">刪除</el-button>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td>
                  <el-input-number v-model="fileSet.weight" :min="0" :step="1" :precision="1"></el-input-number>
                </td>
                <td>
                  <el-input-number v-model="fileSet.fee" :min="0" :step="1" :precision="1"></el-input-number>
                </td>
                <td>
                  <el-button type="primary" :disabled="!fileSet.weight || !fileSet.fee" @click="addFileSet">添加設置</el-button>
                </td>
              </tr>
              </tfoot>
            </table>
          </el-form-item>
          <el-form-item label="包裹費用設置">
            <table class="table">
              <tbody>
              <tr>
                <th>重量</th>
                <th>收費</th>
                <th>操作</th>
              </tr>
              <tr v-for="(set, key) in packSettings" :key="key">
                <td>{{ set.weight }}</td>
                <!--<td>{{ set.fee }}</td>-->
                <td>
                  <el-input-number v-model="set.fee" :min="0" :step="1" :precision="1"></el-input-number>
                </td>

                <td>
                  <el-button  type="danger" @click="removePackSet(set.weight)">刪除</el-button>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td>
                  <el-input-number v-model="packSet.weight" :min="0" :step="1" :precision="1"></el-input-number>
                </td>
                <td>
                  <el-input-number v-model="packSet.fee" :min="0" :step="1" :precision="1"></el-input-number>
                </td>
                <td>
                  <el-button type="primary" :disabled="!packSet.weight || !packSet.fee" @click="addPackSet">添加設置</el-button>
                </td>
              </tr>
              </tfoot>
            </table>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="save()">保存</el-button>
          </el-form-item>

        </el-form>
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import _ from 'lodash';
import { getLoadingInstance } from '@/helpers';
import { mapState } from 'vuex';

export default {
  name: 'AreaGroupShippingFee',
  data() {
    return {
      title: '收費設置',
      tabActiveName: '',
      shippingFeeForm: {
        id: null,
        name: '',
        fee: [],
      },
      fileSettings: [],
      fileSet: {
        weight: 0,
        fee: 0,
      },
      packSettings: [],
      packSet: {
        weight: 0,
        fee: 0,
      },
      shippingFeeFormRules: {
        // weight_unit: { required: true, message: '請選擇重量單位', trigger: 'change' },
        // currency: { required: true, message: '請選擇貨幣', trigger: 'change' },
      },
    };
  },
  computed: {
    ...mapState({
    }),
  },
  methods: {
    addFileSet() {
      if (_.find(this.fileSettings, { weight: this.fileSet.weight })) {
        this.$notify.error({
          message: '該重量已設置收費',
        });
        return;
      }
      this.fileSettings.push(this.fileSet);
      this.fileSettings = _.orderBy(this.fileSettings, 'weight', 'asc');
      this.fileSet = {
        weight: 0,
        fee: 0,
        editable: false,
      };
    },
    removeFileSet(weight) {
      this.fileSettings = _.reject(this.fileSettings, { weight });
    },
    addPackSet() {
      if (_.find(this.packSettings, { weight: this.packSet.weight })) {
        this.$notify.error({
          title: '該重量已設置收費',
        });
        return;
      }
      this.packSettings.push(this.packSet);
      this.packSettings = _.orderBy(this.packSettings, 'weight', 'asc');
      this.packSet = {
        weight: 0,
        fee: 0,
      };
    },
    removePackSet(weight) {
      this.packSettings = _.reject(this.packSettings, { weight });
    },
    save() {
      if (this.$refs.shippingFeeForm.validate((valid) => {
        if (valid) {
          // const loading = getLoadingInstance();
          const params = {
            id: this.shippingFeeForm.id,
            name: this.shippingFeeForm.name,
            fileSettings: this.fileSettings,
            packSettings: this.packSettings,
          };
          this.$api.group.save(params).then(({ data: response }) => {
            if (!this.shippingFeeForm.id) {
              this.$router.push({ name: 'config', params: { id: response.data.id } });
            }
            this.$notify({
              title: '修改成功',
              type: 'success',
            });
          }).catch((e) => {
            this.$notify.error({
              message: e.response.data.message,
            });
          }).finally(() => {
            // loading.close();
          });
        }
      }));
    },
  },
  watch: {
    '$route.params.id': {
      handler(id) {
        console.log(id);
        if (id) {
          this.shippingFeeForm.id = id;
          const loading = getLoadingInstance();
          this.$api.group.get(id).then(({ data: response }) => {
            this.shippingFeeForm = response.data;
            this.fileSettings = _.map(response.data.fileSettings, item => _.assign(item, { editable: false }));
            this.packSettings = response.data.packSettings;
          }).catch((e) => {
            this.$notify.error({
              title: e.response.data.message,
            });
          }).finally(() => {
            loading.close();
          });
        }
      },
      immediate: true,
    },
  },
  components: {},
};
</script>
<style lang="scss">

</style>
